import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { appWithTranslation } from 'next-i18next';


import NProgress from 'nprogress';
import 'nprogress/nprogress.css'; // Import NProgress CSS
import { useRouter } from 'next/router';

import "bootstrap/scss/bootstrap.scss";
import "../public/scss/default/euclid-circulara.scss";

// ========= Plugins CSS START =========
import "../node_modules/sal.js/dist/sal.css";
import "../public/css/plugins/fontawesome.min.css";
import "../public/css/plugins/feather.css";
import "../public/css/plugins/odometer.css";
import "../public/css/plugins/animation.css";
import "../public/css/plugins/euclid-circulara.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import 'tailwindcss/tailwind.css';
import '../styles/globals.css';

// import "../components/Slider/slider.css"
// ========= Plugins CSS END =========

import sal from "sal.js";
import "../public/scss/styles.scss";

function App({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");

    // Inject custom NProgress CSS for z-index
    const style = document.createElement("style");
    style.innerHTML = `
      #nprogress .bar {
        z-index: 10600; /* Adjust the value if necessary */
      }
    
    `  ;
    document.head.appendChild(style);

    const handleStart = () => NProgress.start();
    const handleStop = () => NProgress.done();

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);

      // Clean up the style tag if necessary
      if (style && style.parentNode) {
        style.parentNode.removeChild(style);
      }
    };

  }, [router]);

  return <Component {...pageProps} />;
}

export default appWithTranslation(App);
